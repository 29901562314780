import React from 'react';
import { TwitterIcon, LinkedInIcon, GitHubIcon, DevToIcon } from '../img/icons';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="flex flex-col-reverse md:flex-row items-center md:justify-between max-w-2xl md:max-w-xl mx-auto w-full p-6 py-8 md:p-4">
        <div className="flex items-center">
          <div className="text-xs text-disabled-on-surface-0">
            Built with{' '}
            <a
              className="no-underline font-medium"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.gatsbyjs.org"
            >
              Gatsby
            </a>{' '}
            and{' '}
            <a
              className="no-underline font-medium"
              target="_blank"
              rel="noopener noreferrer"
              href="https://tailwindcss.com/"
            >
              Tailwind CSS
            </a>
          </div>
        </div>

        <div className="flex items-center">
          <a
            className="no-underline p-6"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/STuranszky"
          >
            <TwitterIcon className="fill-current space-x-0h-8 w-8 md:h-6 md:w-6" />
          </a>

          <a
            className="no-underline p-6 md:pl-4"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/sandor-turanszky-ab716447/"
          >
            <LinkedInIcon className="fill-current h-8 w-8 md:h-6 md:w-6" />
          </a>
          <a
            className="no-underline p-6 md:pl-4"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/sandorTuranszky"
          >
            <GitHubIcon className="fill-current h-8 w-8 md:h-6 md:w-6" />
          </a>
          <a
            className="no-underline p-6 md:pl-4 md:pr-0"
            target="_blank"
            rel="noopener noreferrer"
            href="https://dev.to/sandorturanszky"
          >
            <DevToIcon className="fill-current h-16 w-16 md:h-10 md:w-10" />
          </a>
        </div>
      </footer>
    );
  }
};

export default Footer;
