import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, withPrefix, StaticQuery } from 'gatsby'
import { Location } from '@reach/router'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SlidingMenu from '../components/SlidingMenu'
import useSiteMetadata from './SiteMetadata'

class SeoMetaTags extends React.Component {
  render() {
    const { data, siteUrl } = this.props
    const { edges: seo } = data.allMarkdownRemark
    const {
      canonical_url,
      language,
      title,
      description,
      keywords,
      og_title,
      og_description,
      og_url,
      og_site_name,
      og_type,
      og_image,
      appId,
    } = seo[0].node.frontmatter //Since we have only one element, we can access it on zero index. For multilang the correct eleement should be picked by language prop value

    return (
      <Helmet>
        <html
          lang={language}
          className="font-roboto text-base md:text-xl leading-normal tracking-normal"
        />
        <title>{title}</title>
        {canonical_url && <link rel="canonical" key={canonical_url} href={canonical_url} />}
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no"
        />
        {/* keywords are optional */}
        {keywords && <meta name="keywords" content={keywords.join(', ')} />}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.ico`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.ico`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta property="fb:app_id" content={appId} />
        <meta property="og:type" content={og_type} />
        <meta property="og:title" content={og_title || title} />
        <meta property="og:description" content={og_description}></meta>
        <meta property="og:site_name" content={og_site_name}></meta>
        <meta property="og:url" content={og_url} />
        <meta property="og:image" content={`${siteUrl}${og_image}`} />
      </Helmet>
    )
  }
}

SeoMetaTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  siteUrl: PropTypes.string,
}

export default ({ children }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <StaticQuery
      query={graphql`
        query SeoTagQuery {
          allMarkdownRemark(filter: { frontmatter: { dataType: { eq: "seo" } } }, limit: 10) {
            edges {
              node {
                id
                frontmatter {
                  canonical_url
                  language
                  keywords
                  og_description
                  og_image
                  og_site_name
                  og_title
                  og_type
                  og_url
                  title
                  description
                  appId
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {/* needed for a sticky footer */}
          <div className="sliding-content flex flex-col w-full min-h-screen bg-surface-0 text-high-on-surface-0 transition duration-100 ease-in-out">
            <SeoMetaTags data={data} siteUrl={siteUrl} />
            <Location>
              {({ location }) => (
                <>
                  <Navbar location={location} />
                  {/* * flex-1 flex-col needed for a sticky footer */}
                  <main className="flex-1 flex-col mx-auto max-w-2xl md:max-w-xl w-full">
                    {children}
                  </main>
                  <Footer />
                </>
              )}
            </Location>
          </div>
          <SlidingMenu />
        </>
      )}
    />
  )
}
