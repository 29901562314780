import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import Headroom from 'react-headroom'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { isBrowser } from 'react-device-detect'
import { SunIcon, MoonIcon } from '../img/icons'
import ToggleMenuIcon from './ToggleMenuIcon'
import MenuContent from './MenuItems'

function Navbar({ location }) {
  const { site, allMarkdownRemark } = useStaticQuery(graphql`
    query HeaderDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(filter: { frontmatter: { dataType: { eq: "settings" } } }) {
        edges {
          node {
            id
            frontmatter {
              showMenuOnDesktop
              showMenuOnMobile
              showBlog
            }
          }
        }
      }
    }
  `)

  const { edges: settings } = allMarkdownRemark
  const { showMenuOnDesktop, showMenuOnMobile, showBlog } = settings[0].node.frontmatter

  return (
    <Headroom>
      <header className="bg-surface-0 transition duration-100 ease-in-out shadow w-full z-10 top-0">
        <nav className="flex flex-no-wrap items-center justify-between flex-wrap max-w-2xl md:max-w-xl mx-auto w-full p-6 md:p-4">
          {showMenuOnMobile && (
            <div className="block lg:hidden flex-none">
              <ToggleMenuIcon location={location} />
            </div>
          )}
          <div className="flex items-left flex-shrink-0 mr-6 flex-none">
            <Link className="no-underline" to="/">
              <span className="font-bold text-xl">{site.siteMetadata.title}</span>
            </Link>
          </div>
          <div className="block md:order-last flex-none">
            {/* theme */}
            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <button
                  className="flex items-center px-3 py-2"
                  onClick={() => toggleTheme(theme === 'light' ? 'dark' : 'light')}
                >
                  {theme === 'light' ? <SunIcon /> : <MoonIcon />}
                </button>
              )}
            </ThemeToggler>
            {/* theme */}
          </div>
          {/* menu */}
          {showMenuOnDesktop && isBrowser && (
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="text-sm flex lg:flex-grow justify-center md:justify-end">
                <MenuContent showBlog={showBlog} />
              </div>
            </div>
          )}
        </nav>
      </header>
    </Headroom>
  )
}

export default Navbar
